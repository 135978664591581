body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* a {
  color: inherit;
}

a:hover {
  color: inherit;
} */

hr {
  width: 80%;
}

.spaced {
  padding-bottom: 4px;
}

.App {
  color: #565458;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.Header {
  margin-bottom: 16px;
  text-align: center;
}

h1,
h2,
h3 {
  font-family: "Playfair Display", serif;
  text-align: center;
}

.fancy-text {
  font-family: "Playfair Display", serif;
}

.hover-again:hover:after {
  content: " again!";
}

.Details {
  font-size: 20px;
}

.Dot {
  padding-left: 8px;
  padding-right: 8px;
}

.Hero {
  background-image: url("./images/SlavaBlazerPhotography-1.jpg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  position: relative;
}

.Container {
  position: absolute;
  width: 100%;
  padding: 16px;
  bottom: 0;
}

.p-tile {
  background-image: url("./images/tile-slice-flipped.png");
  background-position: right;
  background-repeat: repeat-y;
  background-size: 10%;
}
