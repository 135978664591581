.fund-icon {
  width: 25%;
  height: 150px;
  margin: 8px;
}

.registry-list .media {
  transition: background-color 0.15s ease-in-out;
}

.registry-list .media:hover {
  background-color: #e2e6ea;
}

.registry-list .media-body {
  padding-left: 8px;
}

.registry-list li {
  margin-bottom: 32px;
}
