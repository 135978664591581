.DetailsList .infoRow:not(:last-child):after {
  content: "";
  display: block;
  width: 80%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin-top: 24px;
  margin-bottom: 24px;
}

.hotel-row i {
  margin-right: 4px;
}

.time-to-destination {
  display: inline;
}

.hotel-row > div:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  margin-right: 8px;
}
